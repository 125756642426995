
<template>
  <div class="bill-details">
    <div class="sign-record">
      <div class="list" v-if="renewRecordList.length > 0">
        <div class="item" :key="index">
          <!-- <div class="data">{{ item.time }}</div> -->
          <div
            class="listn"
            v-for="(item, index) in renewRecordList"
            :key="index"
          >
            <div class="itemn acea-row row-between-wrapper">
              <div class="text">
                <div class="name line1">
                  {{ item.typeName }}{{ item.level_name }}
                </div>
                <div>
                  <span>{{ item.payTime }}</span>
                </div>

                <div class="time">{{ item.address }}</div>
                <div class="time" v-if="item.is_express == 1 && item.address != '' && item.address != null && item.express_number != '' && item.express_number != null">
                  {{ item.express_name }}：{{ item.express_number }}
                </div>
              </div>

              <div
                class="right"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                "
              >
                <div class="num">{{ item.vipmoney }}</div>
                <van-button
                  type="primary"
                  size="mini"
                  v-if="item.is_express == 1 && (item.address == '' || item.address == null)"
                  @click="addressTap(item.id)"
                  >设置收货地址</van-button
                >
                <div v-if="item.is_express == 1 && item.address != '' && item.address != null && (item.express_number == '' || item.express_number == null)">待发货</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="emptyData" v-show="isEmptyData">
        <img src="@assets/images/emptyData.png" />
        <p>暂无数据</p>
      </div>
    </div>

    <!-- <div v-if="renewRecordList.length > 0">
      <van-cell v-for="(item, index) in renewRecordList" :key="index">
        <div slot="title">{{ item.typeName }}{{ item.level_name }}对方是否</div>
        <div slot="default">
          <div class="price">{{ item.vipmoney }}</div>
          <van-button
            type="primary"
            size="mini"
            v-if="(item.is_express == 1 && item.address == '') || null"
            @click="addressTap(item.id)"
            >设置收货地址</van-button
          >
          <div>{{ item.express_name }}</div>
          <div>待发货</div>
        </div>
        <div slot="label">
          <div>{{ item.payTime }}</div>
          <div>{{ item.address }}但是拿单反的南方南方的NSF看见你</div>
          <div>{{ item.express_number }}</div>
        </div>
      </van-cell>
    </div> -->

    <!-- <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div> -->
    <van-loading
      class="van-loading"
      size="24px"
      type="spinner"
      v-show="isLoading"
      >加载中...</van-loading
    >

    <AddressWindow
      @checked="changeAddress"
      @redirect="addressRedirect"
      v-model="showAddress"
      :checked="addressInfo.id"
      ref="mychild"
    ></AddressWindow>
  </div>
</template>
<script>
import AddressWindow from "@components/AddressWindow";
import Vue from "vue";
import { Cell, CellGroup, Button } from "vant";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
import { renewRecord, renewRecordAssress } from "../../api/user";

export default {
  name: "RenewRecord",
  components: {
    AddressWindow,
  },
  props: {},
  data: function () {
    return {
      renewRecordList: [], //续费列表
      isLoading: true,
      showAddress: false,
      isEmptyData: false,
      addressInfo: {},
      id: "",
    };
  },
  watch: {},
  computed: {
  },
  mounted: function () {
    this.getRenewRecord();
  },
  methods: {
    // 续费记录列表
    getRenewRecord() {
      let that = this;
      that.initLoading = false;
      renewRecord().then(
        (res) => {
          that.isLoading = false;
          this.isEmptyData = true;
          console.log(res.data);
          that.renewRecordList = res.data;
        },
        (error) => {
          that.isLoading = false;
          this.isEmptyData = true;
          that.$dialog.message(error.msg);
        }
      );
    },
    addressTap: function (id, index) {
      this.id = id;
      this.showAddress = true;
      if (!this.addressLoaded) {
        this.addressLoaded = true;
        this.$refs.mychild.getAddressList();
      }
    },
    addressRedirect() {
      this.showAddress = false;
    },
    changeAddress(addressInfo) {
      this.addressInfo = addressInfo;
      console.log(this.addressInfo);
      let params = {
        id: this.id,
        realname: addressInfo.real_name,
        phone: addressInfo.phone,
        address: addressInfo.province + addressInfo.city + addressInfo.detail,
      };
      renewRecordAssress(params).then(
        (res) => {
          this.getRenewRecord();
        },
        (error) => {
          this.$dialog.message(error.msg);
        }
      );
      console.log(params.address);
    },
  },
};
</script>
<style lang="scss" scoped>
.itemn {
  .text {
    .time {
      padding-bottom: 0.1rem;
    }
  }
  .num {
    color: #daa070 !important;
  }
}
</style>

